import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';  // Import serverTimestamp
import { db } from './firebase';
import { Layout, Table, Input, Button, Form, Typography, notification, AutoComplete, Popconfirm } from 'antd';
import { PrinterFilled, EditOutlined, DeleteOutlined, UserOutlined, MailOutlined, MessageOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import '../styles/SolarCalculations.css';
import translateText from '../translationService'; // Import your translation service

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

const suggestedItems = [
  'LED Light Bulb',
  'Refrigerator',
  'Air Conditioner',
  'Laptop',
  'Television',
  'Washing Machine',
  'Microwave Oven',
  'Ceiling Fan',
  'Water Heater',
  'Solar Inverter',
];

const SolarCalculationPage = ({ language }) => {
  const [dataSource, setDataSource] = useState([
    { key: '1', item: 'Demo Item', quantity: 1, watts: 100, hours: 1, wattHour: 100 },
  ]);
  const [count, setCount] = useState(2);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();  // Create form instance for table editing
  const [inputForm] = Form.useForm();  // Create form instance for new item inputs

  const [labels, setLabels] = useState({
    name: "Your Name",
    email: "Your Email",
    message: "Additional Message",
    addItem: "Add Item",
    submit: "Submit",
    header: "Solar Calculation",
    title: "Solar Power Consumption Calculator",
    description: "Use this calculator to estimate your solar power needs. Add items, their quantities, and power ratings below.",
    item: "Item",
    quantity: "Quantity",
    watts: "Watts",
    hours: "Hours",
    wattHour: "Watt Hour",
    action: "Action",
    total: "Total",
    printResult: "Print Result",
    simpleCalculator: "This is just a simple Calculator to help you estimate the solar you need. This is not a guarantee that the solar you calculate will be perfect for your situation.",
    sureToDelete: "Sure to delete?",
    pleaseInput: "Please input the",
  });

  const translateLabels = async () => {
    const translatedLabels = {};
    for (const [key, value] of Object.entries(labels)) {
      translatedLabels[key] = await translateText(value, language);
    }
    setLabels(translatedLabels);
  };

  useEffect(() => {
    translateLabels();
  }, [language]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      item: '',
      quantity: '',
      watts: '',
      hours: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const deleteRow = (key) => {
    const newData = dataSource.filter(item => item.key !== key);
    setDataSource(newData);
  };

  const columns = [
    {
      title: labels.item,
      dataIndex: 'item',
      key: 'item',
      editable: true,
    },
    {
      title: labels.quantity,
      dataIndex: 'quantity',
      key: 'quantity',
      editable: true,
    },
    {
      title: labels.watts,
      dataIndex: 'watts',
      key: 'watts',
      editable: true,
    },
    {
      title: labels.hours,
      dataIndex: 'hours',
      key: 'hours',
      editable: true,
    },
    {
      title: labels.wattHour,
      dataIndex: 'wattHour',
      key: 'wattHour',
    },
    {
      title: labels.action,
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
              icon={<SaveOutlined />}
            >
            </Button>
            <Button onClick={cancel} icon={<CloseOutlined />}></Button>
          </span>
        ) : (
          <span>
            <Button 
              disabled={editingKey !== ''} 
              onClick={() => edit(record)} 
              icon={<EditOutlined />}
              style={{ marginRight: 8 }}
            >
            </Button>
            <Popconfirm title={labels.sureToDelete} onConfirm={() => deleteRow(record.key)}>
              <Button 
                disabled={editingKey !== ''} 
                icon={<DeleteOutlined />} 
                danger
              >
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'quantity' || col.dataIndex === 'watts' || col.dataIndex === 'hours' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${labels.pleaseInput} ${title}!`,
              },
            ]}
          >
            <Input type={inputType} />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const onFinish = (values) => {
    // Remove demo item if it exists
    const filteredDataSource = dataSource.filter(item => item.item !== 'Demo Item');

    const newData = {
      key: count.toString(),
      item: values.item,
      quantity: values.quantity,
      watts: values.watts,
      hours: values.hours,
      wattHour: values.quantity * values.watts * values.hours,
    };

    setDataSource([newData, ...filteredDataSource]); // Add new item at the top
    setCount(count + 1);
    inputForm.resetFields(); // Clear form inputs
  };

  const totalWatts = dataSource.reduce((acc, item) => acc + item.watts * item.quantity, 0);
  const totalWattHours = dataSource.reduce((acc, item) => acc + item.wattHour, 0);

  const handleSubmit = async (values) => {
    try {
      // Send the data to the backend
      const response = await addDoc(collection(db, 'submissions'), {
        data: { dataSource, ...values },
        createdAt: serverTimestamp(),  // Set createdAt with serverTimestamp
        updatedAt: serverTimestamp(),  // Set updatedAt with serverTimestamp
      });

      // Display a success notification
      notification.success({
        message: 'Submission Successful',
        description: 'The data has been submitted to the admin.',
      });

      // Log the response data
      console.log('Response:', response.id);  // response.data doesn't exist, use response.id
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
      notification.error({
        message: 'Submission Failed',
        description: 'There was an error while submitting the data.',
      });
    }
  };

  const handlePrint = () => {
    window.print();
  }

  return (
    <Layout className='calculations-container' style={{ minHeight: '100vh' }}>
      <Header style={{ backgroundColor: '#001529', padding: '0 50px', textAlign: 'center' }}>
        <Title level={2} style={{ color: 'white', lineHeight: '64px' }}>{labels.header}</Title>
      </Header>
      <Content className='calculations-content' style={{ padding: '50px' }}>
        <Title level={3}>{labels.title}</Title>
        <Paragraph>
          {labels.description}
        </Paragraph>
        <Form className='solar-calcualtions-table' form={form} component={false}>
          <Table
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={3}><b>{labels.total}</b></Table.Summary.Cell>
                  <Table.Summary.Cell><b>{totalWatts} W</b></Table.Summary.Cell>
                  <Table.Summary.Cell><b>{totalWattHours} Wh</b></Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              </Table.Summary>
            )}
            scroll={{ x: 600, y: 240 }}
          />
        </Form>
        
        <Form className='addup-form' form={inputForm} layout="inline" onFinish={onFinish} style={{ marginTop: '20px', gap: '20px' }}>
          <Form.Item name="item" rules={[{ required: true, message: 'Please input the item!' }]}>
            <AutoComplete
              style={{ width: '200px', background: 'transparent' }}
              options={suggestedItems.map(item => ({ value: item }))}
              placeholder={labels.item}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item name="quantity" rules={[{ required: true, message: 'Please input the quantity!' }]}>
            <Input type="number" placeholder={labels.quantity} style={{ background: 'transparent' }} />
          </Form.Item>
          <Form.Item name="watts" rules={[{ required: true, message: 'Please input the watts!' }]}>
            <Input type="number" placeholder={labels.watts} style={{ background: 'transparent' }} />
          </Form.Item>
          <Form.Item name="hours" rules={[{ required: true, message: 'Please input the hours!' }]}>
            <Input type="number" placeholder={labels.hours} style={{ background: 'transparent' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">{labels.addItem}</Button>
          </Form.Item>
        </Form>

        <Paragraph style={{ padding: '2rem 0' }}>
          {labels.simpleCalculator}
        </Paragraph>

        <Paragraph style={{ fontWeight: "bold" }}>
          {labels.printResult}: &nbsp;
          <Button onClick={handlePrint}>
            <PrinterFilled />
          </Button>
        </Paragraph>

        <Form className='submit-form' onFinish={handleSubmit} style={{ marginTop: '40px', maxWidth: '500px' }}>
          <Title level={4}>{labels.submit}</Title>
          <Form.Item 
            name="name" 
            rules={[{ required: true, message: 'Please input your name!' }]}
            prefix={<UserOutlined />}
          >
            <Input placeholder={labels.name} style={{ background: 'transparent' }} />
          </Form.Item>
          <Form.Item 
            name="email" 
            rules={[{ required: true, message: 'Please input your email!' }]}
            prefix={<MailOutlined />}
          >
            <Input placeholder={labels.email} style={{ background: 'transparent' }} />
          </Form.Item>
          <Form.Item 
            name="message"
            prefix={<MessageOutlined />}
          >
            <Input.TextArea rows={4} placeholder={labels.message} style={{ background: 'transparent' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">{labels.submit}</Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default SolarCalculationPage;
